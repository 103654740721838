import React from "react"
import { Color, getBgColorStyle, getColorStyle } from "../utils/color"
import "./card.css"

type Link =
  | "/"
  | "/curso-amplifica-tu-confianza"
  | "/programa-the-confidence-revolution"
  | "/power-coaching"
  | "/sobre-mi"
  | "/recursos-gratuitos"

const CardComponent: React.FC<{
  avatar?: string
  className?: string
  bgColor?: Color | string
  color?: Color | string
  shadow?: boolean
  link?: Link
}> = ({ children, avatar, className, color, bgColor, link, shadow }) =>
  !!link ? (
    <a
      href={link || "#"}
      onClick={e => {
        if (!link) e.preventDefault()
      }}
      className={`card${avatar ? " avatar " : " "}${className} ${
        shadow ? "shadow" : ""
      }`}
      style={{ ...getBgColorStyle(bgColor), ...getColorStyle(color) }}
    >
      {children}
    </a>
  ) : (
    <div
      className={`card${avatar ? " avatar " : " "}${className} ${
        shadow ? "shadow" : ""
      }`}
      style={{ ...getBgColorStyle(bgColor), ...getColorStyle(color) }}
    >
      {children}
    </div>
  )

export interface CardProps {
  icon?: React.ReactElement
  avatar?: string
  title?: string | React.ReactElement
  className?: string
  shadow?: boolean
  titleClassName?: string
  bgColor?: Color | string
  color?: Color | string
  titleColor?: Color | string
  link?: Link
}

const Card: React.FC<CardProps> = ({
  children,
  icon,
  title,
  avatar,
  className = "",
  titleClassName = "",
  color = "text-color",
  titleColor = "text-color",
  bgColor = "white",
  shadow = true,
  link,
}) => (
  <CardComponent
    avatar={avatar}
    className={className}
    shadow={shadow}
    color={color}
    bgColor={bgColor}
    link={link}
  >
    {icon && (
      <div
        className="card-icon"
        style={{ ...getBgColorStyle(bgColor), ...getColorStyle(color) }}
      >
        {icon}
      </div>
    )}
    {avatar && <img src={avatar} alt={avatar} />}
    {title && (
      <h3 className={titleClassName} style={getColorStyle(titleColor)}>
        {title}
      </h3>
    )}
    {typeof children === "string" ? <p>{children}</p> : children}
  </CardComponent>
)

export default Card
