export type Color =
  | "blue"
  | "blue1"
  | "blue2"
  | "turkis-blue1"
  | "turkis-blue2"
  | "turkis-light"
  | "white"
  | "beige1"
  | "beige2"
  | "gold1"
  | "gold2"
  | "text-color"
  | "secondary-color"

export const getColorValue = (color: Color | string): string =>
  color.includes("#") ? color : `var(--${color})`

export const getColorStyle = (color: Color | string): { color: string } => ({
  color: getColorValue(color),
})
export const getBgColorStyle = (
  bgColor: Color | string
): { backgroundColor: string } => ({ backgroundColor: getColorValue(bgColor) })
