import React from "react"
import { Color, getBgColorStyle } from "../utils/color"
import "./section.css"

type Overlay = "full" | "part" | "none"

interface SectionProps {
  color: Color | string
  overlayColor?: string // rgba
  bgImage?: string
  overlay?: Overlay
  withTextOverlay: boolean
  overlayClassName?: string
  id?: string
  small?: boolean
}

const Section: React.FC<SectionProps> = ({
  children,
  color = "white",
  overlayColor = "rgba(0, 0, 0, 0.5)",
  bgImage,
  overlay = "none",
  overlayClassName = "",
  id = "",
  small = false,
}) => {
  const bgImageStyle = bgImage
    ? {
        backgroundImage: `url(${bgImage})`,
      }
    : {}
  const bgColorStyle = getBgColorStyle(color)

  return (
    <section
      className={`section ${bgImage ? "bg" : "content"} ${
        small ? "small" : ""
      }`}
      style={{ ...bgImageStyle, ...bgColorStyle }}
      id={id}
    >
      {overlay !== "none" && (
        <div
          className={`overlay ${overlay} ${overlayClassName}`}
          style={{ backgroundColor: overlayColor }}
        />
      )}
      {children}
    </section>
  )
}

export default Section
