import React from "react"

import { RiInstagramFill, RiFacebookCircleFill } from "react-icons/ri"

import "./footer.css"

const Footer: React.FC<{ bottom: boolean }> = ({ bottom = false }) => {
  const date = new Date()
  return (
    <div className={`footer ${bottom ? "bottom" : ""}`}>
      <div className="discalimer">
        Descargo de Responsabilidad: Los resultados no pueden garantizarse. Yo
        comparto contigo las estrategias. La efectividad y los resultados
        dependen de tu compromiso contigo misma, con tu proceso y de tu propia
        determinación.
      </div>
      <div className="copy-right">
        {`©${date.getFullYear()} Ivone Herrera Todos los derechos reservados. `}

        <a href="https://www.facebook.com/ivone.herreraCoa/" target="_blank">
          <RiFacebookCircleFill size="22px" />
        </a>
        <a
          href="https://www.instagram.com/expat_coach_alemania/"
          target="_blank"
        >
          <RiInstagramFill size="22px" />
        </a>
      </div>
    </div>
  )
}

export default Footer
