import React from "react"
import "./article.css"

type Layout = "wide" | "narrow"

interface Props {
  layout: Layout
  title?: string | React.ReactElement
  subtitle?: string | React.ReactElement
  className?: string
  titleClassName?: string
  subtitleClassName?: string
}

const Article: React.FC<Props> = ({
  children,
  layout = "wide",
  title,
  subtitle,
  className = "",
  titleClassName = "",
  subtitleClassName = "",
}) => (
  <article className={`article ${layout} ${className}`}>
    {title && <h2 className={titleClassName}>{title}</h2>}
    {subtitle && <div className={subtitleClassName}>{subtitle}</div>}
    <div className="article-content">{children}</div>
  </article>
)

export default Article
